import { ThunkAction } from "redux-thunk";
import { AppStateType } from "..";
import {
  SET_CALLS,
  SET_IS_CALLS_LOADING_IN_PROGRESS,
  SET_SMS,
  SET_IS_SMS_SEND_IN_PROGRESS,
} from "../reducers/callsReducer";
import { callsApi } from "../../api";
import { notification } from "antd";
import { ICall, ISms } from "../../types";

type SetCalls = {
  type: typeof SET_CALLS;
  payload: { calls: ICall[] };
};

type SetSms = {
  type: typeof SET_SMS;
  payload: { sms: ISms[] };
};

type SetIsCallsLoadingInProgress = {
  type: typeof SET_IS_CALLS_LOADING_IN_PROGRESS;
  payload: { isCallsLoadingInProgress: boolean };
};

type SetIsSmsSendInProgress = {
  type: typeof SET_IS_SMS_SEND_IN_PROGRESS;
  payload: { isSmsSendInProgress: boolean };
};

export type CallsActionsTypes =
  | SetCalls
  | SetIsCallsLoadingInProgress
  | SetSms
  | SetIsSmsSendInProgress;

export const setCalls = (calls: ICall[]): SetCalls => ({
  type: SET_CALLS,
  payload: { calls },
});

export const setSms = (sms: ISms[]): SetSms => ({
  type: SET_SMS,
  payload: { sms },
});

export const setIsCallsLoadingInProgress = (
  isCallsLoadingInProgress: boolean
): SetIsCallsLoadingInProgress => ({
  type: SET_IS_CALLS_LOADING_IN_PROGRESS,
  payload: { isCallsLoadingInProgress },
});

export const setIsSmsSendInProgress = (
  isSmsSendInProgress: boolean
): SetIsSmsSendInProgress => ({
  type: SET_IS_SMS_SEND_IN_PROGRESS,
  payload: { isSmsSendInProgress },
});

export type CallsThunkType = ThunkAction<
  Promise<void>,
  AppStateType,
  unknown,
  CallsActionsTypes
>;

export const getCalls =
  (payload: any): CallsThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsCallsLoadingInProgress(true));
      const response = await callsApi.loadCalls(payload);
      if (response.status === "success") {
        console.log(response.data);
        dispatch(setCalls(response.data));
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error loading list of calls",
      });
    } finally {
      dispatch(setIsCallsLoadingInProgress(false));
    }
  };

export const getSms =
  (payload: any): CallsThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsCallsLoadingInProgress(true));
      const response = await callsApi.loadSms(payload);
      if (response.status === "success") {
        dispatch(setSms(response.data));
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error loading list of sms",
      });
    } finally {
      dispatch(setIsCallsLoadingInProgress(false));
    }
  };

export const sendSms =
  (from: string, to: string, text: string): CallsThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsSmsSendInProgress(true));
      const response = await callsApi.sendSms(from, to, text);
      if (response.status === "success") {
        notification.success({
          message: "Success",
          description: "sms sent",
        });
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error sending sms",
      });
    } finally {
      dispatch(setIsSmsSendInProgress(false));
    }
  };
