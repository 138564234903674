import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import Root from "./routes/Root";
import ErrorPage from "./Error-page";
import { Sites } from "./routes/Sites";
import { Site } from "./routes/Site";
import { Login } from "./routes/Login";
import { RequireAuth } from "./RequireAuth";
import { Main } from "./routes/Main";
import { Companies } from "./routes/Companies";
import { Company } from "./routes/Company";
import { Categories } from "./routes/Categories";
import { Templates } from "./routes/Templates";
import { Template } from "./routes/Template";
import { Logs } from "./routes/Logs";
import { Tags } from "./routes/Tags";
import { Tag } from "./routes/Tag";
import { Images } from "./routes/Images";
import { Clicks } from "./routes/Clicks";
import { Calls } from "./routes/Calls";
import { Sms } from "./routes/Sms";

export const Router = () => {
  const items = [
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/",
      element: <Main />,
    },
    {
      path: "/sites",
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <RequireAuth element={<Sites />} />,
        },
        {
          path: "/sites/:id",
          element: <RequireAuth element={<Site />} />,
        },
      ],
    },
    {
      path: "/companies",
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <RequireAuth element={<Companies />} />,
        },
        {
          path: "/companies/:id",
          element: <RequireAuth element={<Company />} />,
        },
      ],
    },
    {
      path: "/categories",
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <RequireAuth element={<Categories />} />,
        },
      ],
    },
    {
      path: "/templates",
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <RequireAuth element={<Templates />} />,
        },
        {
          path: "/templates/:id",
          element: <RequireAuth element={<Template />} />,
        },
      ],
    },
    {
      path: "/tags",
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <RequireAuth element={<Tags />} />,
        },
        {
          path: "/tags/:id",
          element: <RequireAuth element={<Tag />} />,
        },
      ],
    },
    {
      path: "/images",
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <RequireAuth element={<Images />} />,
        },
      ],
    },
    {
      path: "/logs",
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <RequireAuth element={<Logs />} />,
        },
      ],
    },
    {
      path: "/clicks",
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <RequireAuth element={<Clicks />} />,
        },
      ],
    },
    {
      path: "/calls",
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <RequireAuth element={<Calls />} />,
        },
      ],
    },
    {
      path: "/sms",
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <RequireAuth element={<Sms />} />,
        },
      ],
    },
  ];

  const router = createBrowserRouter(items);
  return <RouterProvider router={router} />;
};
