import React, { Dispatch, useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { SiteThunkType } from "../store/actions/siteActions";
import { AnyAction } from "redux";
import {
  getCategories,
  setActiveAppliance,
} from "../store/actions/categoriesActions";
import { AppStateType } from "../store";
import { Typography } from "antd";
import {
  getAppliances,
  getApplianceBrands,
  setBrands,
} from "../store/actions/applianceActions";
import { SearchBrandInput } from "../components/categories/SearchBrandInput";

import { EditCategoryInput } from "../components/categories/EditCategoryInput";
import { CreateApplianceInput } from "../components/categories/CreateApplianceInput";
import { getDomainsByCategory } from "../store/actions/domainActions";
import { DomainListTextArea } from "../components/categories/DomainListTextArea";
import { CreateCategoryInput } from "../components/categories/CreateCategoryInput";
import { EditMainCategoryInput } from "../components/categories/EditMainCategoryInput";
import { ICategory } from "../types";

export const Categories = () => {
  const [activeCategory, setActiveCategory] = useState<null | string>(null);
  const [category, setCategory] = useState<ICategory | null>(null);

  const { Link, Text } = Typography;
  const dispatch = useDispatch() as Dispatch<SiteThunkType | AnyAction>;

  const categories = useSelector(
    (state: AppStateType) => state.categories.categories
  );

  const activeAppliance = useSelector(
    (state: AppStateType) => state.categories.activeAppliance
  );

  const appliances = useSelector(
    (state: AppStateType) => state.appliance.appliances
  );

  const applianceBrands = useSelector(
    (state: AppStateType) => state.appliance.applianceBrands
  );

  const isGettingBrandsInProgress = useSelector(
    (state: AppStateType) => state.appliance.isGettingBrandsInProgress
  );

  const isGettingAppliancesInProgress = useSelector(
    (state: AppStateType) => state.appliance.isGettingAppliancesInProgress
  );

  const isGettingCategoriesInProgress = useSelector(
    (state: AppStateType) => state.categories.isGettingCategoriesInProgress
  );

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  useEffect(() => {
    if (categories.length > 0 && !activeCategory) {
      setActiveCategory(categories[0]._id);
    }
  }, [categories, activeCategory]);

  useEffect(() => {
    if (activeCategory) {
      dispatch(setBrands([]));
      dispatch(setActiveAppliance(null));
      dispatch(getAppliances(activeCategory));
      dispatch(getDomainsByCategory(activeCategory));
    }
  }, [activeCategory]);

  useEffect(() => {
    if (activeAppliance) {
      dispatch(getApplianceBrands(activeAppliance));
    }
  }, [activeAppliance]);

  useEffect(() => {
    let cat = categories?.find((cat) => cat._id === activeCategory);
    if (cat) {
      setCategory(cat);
    } else {
      setCategory(null);
    }
  }, [activeCategory, categories]);

  return (
    <div
      style={{
        display: "flex",
        padding: 20,
        paddingTop: 20,
        flexDirection: "column",
      }}
    >
      {isGettingCategoriesInProgress && (
        <LoadingOutlined style={{ marginLeft: 10, marginTop: 13 }} />
      )}

      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            paddingRight: 20,
            display: "flex",
            flexDirection: "column",
            borderRight: "1px solid",
          }}
        >
          <h2>Categories</h2>
          {categories?.map((c) => (
            <Link
              style={{
                fontSize: 18,
                fontWeight: activeCategory === c._id ? "bold" : undefined,
                color: activeCategory === c._id ? "red" : undefined,
              }}
              href=""
              onClick={(e) => {
                e.preventDefault();
                setActiveCategory(c._id);
              }}
            >
              {c.name}
            </Link>
          ))}
          <h3>Create category</h3>
          <CreateCategoryInput />
          {activeCategory && (
            <div style={{ marginTop: 20 }}>
              <h3>Edit category</h3>
              <EditMainCategoryInput activeCategory={activeCategory} />
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: 20,
            paddingRight: 20,
            borderRight: "1px solid",
          }}
        >
          <h2>Subcategories</h2>
          {isGettingAppliancesInProgress && (
            <LoadingOutlined style={{ marginLeft: 10, marginTop: 13 }} />
          )}

          {appliances.length === 0 && <div>No subcategories</div>}

          {appliances?.map((c) => (
            <div style={{ flexDirection: "row", padding: 2 }}>
              <Link
                style={{
                  fontSize: 18,
                  fontWeight: activeAppliance === c._id ? "bold" : undefined,
                  color: activeAppliance === c._id ? "red" : undefined,
                }}
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(setActiveAppliance(c._id));
                }}
              >
                {c.name}
              </Link>
            </div>
          ))}
          {activeAppliance && activeCategory && (
            <>
              <h3>Edit subcategory</h3>
              <EditCategoryInput
                activeAppliance={activeAppliance}
                appliances={appliances}
                activeCategory={activeCategory}
              />
            </>
          )}
          {activeCategory && (
            <>
              <h3>Create subcategory</h3>
              <CreateApplianceInput activeCategory={activeCategory} />
            </>
          )}

          {activeCategory && (
            <DomainListTextArea activeCategory={activeCategory} />
          )}
        </div>
        {category?.type === "appliance" && (
          <div
            style={{
              paddingLeft: 20,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {isGettingBrandsInProgress && (
              <LoadingOutlined style={{ marginLeft: 10, marginTop: 13 }} />
            )}
            {activeAppliance && (
              <>
                <h2>Brands</h2>
                <SearchBrandInput />
              </>
            )}
            {applianceBrands?.map((c) => (
              <Text>{c.brand.name}</Text>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
