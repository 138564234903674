// components/SmsAnalyticsTable.tsx

import React, { useState, useEffect, Dispatch } from "react";
import { CallsThunkType, getSms, sendSms } from "../store/actions/callsActions";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../store";
import { Form, FormProps, Input, Button } from "antd";

const { TextArea } = Input;

interface Filters {
  startDate: string; // формат "YYYY-MM-DD" от input type="date"
  endDate: string; // формат "YYYY-MM-DD"
  from: string;
  to: string;
  domain: string;
  page: number;
}

export const Sms = () => {
  const dispatch = useDispatch() as Dispatch<CallsThunkType>;
  const [filters, setFilters] = useState<Filters>({
    startDate: "",
    endDate: "",
    from: "",
    to: "",
    domain: "",
    page: 1,
  });

  // При монтировании задаём диапазон дат по умолчанию: последние 30 дней
  useEffect(() => {
    const now = new Date();
    const priorDate = new Date();
    priorDate.setDate(now.getDate() - 30);

    const pad = (num: number) => (num < 10 ? "0" + num : num);
    const formatDate = (date: Date): string =>
      `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
        date.getDate()
      )}`;

    setFilters((prev) => ({
      ...prev,
      startDate: formatDate(priorDate),
      endDate: formatDate(now),
    }));
  }, []);

  const fetchSms = async () => {
    const payload = {
      ...filters,
      startDate: filters.startDate ? filters.startDate + " 00:00:00" : "",
      endDate: filters.endDate ? filters.endDate + " 23:59:59" : "",
    };

    dispatch(getSms(payload));
  };

  useEffect(() => {
    fetchSms();
  }, []);

  const isCallsLoadingInProgress = useSelector(
    (state: AppStateType) => state.calls.isCallsLoadingInProgress
  );
  const isSmsSendInProgress = useSelector(
    (state: AppStateType) => state.calls.isSmsSendInProgress
  );
  const sms = useSelector((state: AppStateType) => state.calls.sms);

  // Запрашиваем данные при изменении номера страницы
  useEffect(() => {
    if (filters.startDate && filters.endDate) {
      fetchSms();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
      page: 1, // сбрасываем страницу при изменении фильтров
    }));
  };

  const handleSearch = () => {
    fetchSms();
  };

  const handlePrevPage = () => {
    if (filters.page > 1) {
      setFilters((prev) => ({ ...prev, page: prev.page - 1 }));
    }
  };

  const handleNextPage = () => {
    setFilters((prev) => ({ ...prev, page: prev.page + 1 }));
  };

  const options: Intl.DateTimeFormatOptions = {
    timeZone: "America/Los_Angeles",
    hour12: true,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  const [form] = Form.useForm();

  type FieldType = {
    from?: string;
    to?: string;
    text?: string;
  };

  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    console.log("Success:", values);
    if (values.from && values.to && values.text) {
      dispatch(sendSms(values.from, values.to, values.text));
    }
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
    errorInfo
  ) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div style={{ padding: 10 }}>
      <h2>SMS</h2>
      <div className="filters" style={{ marginBottom: "20px" }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <tbody>
                <tr>
                  <td
                    style={{
                      textAlign: "left",
                      padding: "8px",
                      verticalAlign: "middle",
                      width: "140px",
                    }}
                  >
                    Дата (начало):
                  </td>
                  <td style={{ padding: "8px" }}>
                    <input
                      type="date"
                      name="startDate"
                      value={filters.startDate}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      textAlign: "left",
                      padding: "8px",
                      verticalAlign: "middle",
                    }}
                  >
                    Дата (конец):
                  </td>
                  <td style={{ padding: "8px" }}>
                    <input
                      type="date"
                      name="endDate"
                      value={filters.endDate}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      textAlign: "left",
                      padding: "8px",
                      verticalAlign: "middle",
                    }}
                  >
                    От кого:
                  </td>
                  <td style={{ padding: "8px" }}>
                    <input
                      type="text"
                      name="from"
                      value={filters.from}
                      onChange={handleInputChange}
                      placeholder="Поиск по 'От кого'"
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      textAlign: "left",
                      padding: "8px",
                      verticalAlign: "middle",
                    }}
                  >
                    Кому:
                  </td>
                  <td style={{ padding: "8px" }}>
                    <input
                      type="text"
                      name="to"
                      value={filters.to}
                      onChange={handleInputChange}
                      placeholder="Поиск по 'Кому'"
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      textAlign: "left",
                      padding: "8px",
                      verticalAlign: "middle",
                    }}
                  >
                    Домен сайта:
                  </td>
                  <td style={{ padding: "8px" }}>
                    <input
                      type="text"
                      name="domain"
                      value={filters.domain}
                      onChange={handleInputChange}
                      placeholder="Поиск по домену"
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td style={{ padding: "8px" }}>
                    <button onClick={handleSearch}>
                      {isCallsLoadingInProgress ? "Loading..." : "Поиск"}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "flex-end",
              flexDirection: "column",
            }}
          >
            <Form
              //style={{ width: 800 }}
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              form={form}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ width: 300 }}>
                  <Form.Item<FieldType>
                    label="From"
                    name="from"
                    rules={[
                      { required: true, message: "Please input phone number!" },
                      { max: 11, message: "Please enter correct phone!" },
                    ]}
                    labelCol={{ span: 24 }}
                  >
                    <Input placeholder="1615XXXXXXX" />
                  </Form.Item>

                  <Form.Item<FieldType>
                    label="To"
                    name="to"
                    rules={[
                      { required: true, message: "Please input phone number!" },
                      { max: 11, message: "Please enter correct phone!" },
                    ]}
                    labelCol={{ span: 24 }}
                  >
                    <Input placeholder="1615XXXXXXX" />
                  </Form.Item>
                </div>
                <div style={{ width: 500 }}>
                  <Form.Item
                    label="Sms text"
                    name="text"
                    rules={[
                      { required: true, message: "Please input message!" },
                    ]}
                    labelCol={{ span: 24 }}
                  >
                    <TextArea rows={6} />
                  </Form.Item>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: 630,
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  htmlType="submit"
                  type="primary"
                  size="middle"
                  disabled={isSmsSendInProgress}
                  loading={isSmsSendInProgress}
                >
                  Send sms
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>

      <table
        border={1}
        cellPadding={5}
        style={{ width: "100%", borderCollapse: "collapse" }}
      >
        <thead>
          <tr>
            <th>Дата</th>
            <th>От кого</th>
            <th>Кому</th>
            <th>Сообщение</th>
            <th>Domain</th>
          </tr>
        </thead>
        <tbody>
          {sms.length > 0 ? (
            sms.map((smsItem, idx) => (
              <tr key={idx}>
                <td>
                  {new Date(smsItem.createdAt).toLocaleDateString(
                    "en-US",
                    options
                  )}
                </td>
                <td>{smsItem.From}</td>
                <td>{smsItem.To}</td>
                <td>{smsItem.Message.replaceAll("+", " ")}</td>
                <td>{smsItem.siteDomain || "—"}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5}>
                {isCallsLoadingInProgress
                  ? "Loading"
                  : "Нет данных для отображения"}
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <div style={{ marginTop: "20px" }}>
        <button onClick={handlePrevPage} disabled={filters.page === 1}>
          Предыдущая
        </button>
        <span style={{ margin: "0 10px" }}>Страница {filters.page}</span>
        <button onClick={handleNextPage}>Следующая</button>
      </div>
    </div>
  );
};
