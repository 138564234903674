import {
  Breadcrumb,
  Button,
  Form,
  FormProps,
  Input,
  Popconfirm,
  Select,
  Switch,
  Tag,
  Typography,
} from "antd";
import {
  ArrowLeftOutlined,
  LoadingOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import React, { Dispatch, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  SiteThunkType,
  deleteField,
  getOneSite,
  getSpecificPageValues,
  rebuildWithNewContent,
  rewriteContent,
  setInProgressSiteId,
  updateSite,
} from "../store/actions/siteActions";
import { AnyAction } from "redux";
import { AppStateType } from "../store";
import { FieldType, IOnInProgressId, IOnSitesUpdate } from "../types";
import { getTags } from "../store/actions/tagActions";
import { defaultFonts, mainUrl } from "../helpers/constants";
import { StatusBar } from "../components/sites/StatusBar";
import { getTemplateTypes } from "../store/actions/templateActions";
import { SpecificPageValues } from "../components/sites/SpecificPageValues";
import { ReloadOutlined, ApiOutlined } from "@ant-design/icons";
import { TemplatesList } from "../components/sites/TemplatesList";
import {
  getAllApplianceBrands,
  getAllAppliances,
} from "../store/actions/applianceActions";
import {
  deleteAllSiteImages,
  deleteOneSiteImage,
  getSiteImages,
} from "../store/actions/imageActions";

export const Site = () => {
  const dispatch = useDispatch() as Dispatch<SiteThunkType | AnyAction>;
  const { Link, Title } = Typography;
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  let { id } = useParams();
  const [valuesToUpdate, setValuesToUpdate] = useState<{
    [param: string]: string;
  }>({});
  const site = useSelector((state: AppStateType) => state.site.site);
  const socket = useSelector((state: AppStateType) => state.auth.socket);
  const isGettingSitesInProgress = useSelector(
    (state: AppStateType) => state.site.isGettingSitesInProgress
  );
  const isUpdatingSiteInProgress = useSelector(
    (state: AppStateType) => state.site.isUpdatingSiteInProgress
  );

  const inProgressId = useSelector(
    (state: AppStateType) => state.site.inProgressId
  );
  const tags = useSelector((state: AppStateType) => state.tag.tags);

  const templateTypes = useSelector(
    (state: AppStateType) => state.template.templateTypes
  );

  const appliances = useSelector(
    (state: AppStateType) => state.appliance.appliances
  );
  const applianceBrands = useSelector(
    (state: AppStateType) => state.appliance.applianceBrands
  );
  const siteImages = useSelector(
    (state: AppStateType) => state.image.siteImages
  );
  const isImageDeletingInProgress = useSelector(
    (state: AppStateType) => state.image.isImageDeletingInProgress
  );

  const specificPageValues = useSelector(
    (state: AppStateType) => state.site.specificPageValues
  );
  const isRebuildWithNewContentInProgress = useSelector(
    (state: AppStateType) => state.site.isRebuildWithNewContentInProgress
  );
  const isRewriteContentInProgress = useSelector(
    (state: AppStateType) => state.site.isRewriteContentInProgress
  );

  useEffect(() => {
    if (id) {
      dispatch(getOneSite(id));
      dispatch(getTemplateTypes());
      dispatch(getTags(1));
      dispatch(getAllAppliances());
      dispatch(getAllApplianceBrands());
      dispatch(getSiteImages(id));
    }
  }, [id]);

  useEffect(() => {
    console.log("valuesToUpdate", valuesToUpdate);
  }, [valuesToUpdate]);

  useEffect(() => {
    console.log("site", site);
    if (site) {
      form.setFieldValue("name", site.name);
      form.setFieldValue("domain", site.domain);
      form.setFieldValue("status", site.status);
      form2.setFieldValue("headerFont", site.headerFont);
      form2.setFieldValue("paragraphFont", site.paragraphFont);
      form2.setFieldValue("h1Size", site.h1Size);
      form2.setFieldValue("h1SizeMobile", site.h1SizeMobile);
      form2.setFieldValue("h2Size", site.h2Size);
      form2.setFieldValue("h2SizeMobile", site.h2SizeMobile);
      form2.setFieldValue("h3Size", site.h3Size);
      form2.setFieldValue("h3SizeMobile", site.h3SizeMobile);
      form2.setFieldValue("h4Size", site.h4Size);
      form2.setFieldValue("h4SizeMobile", site.h4SizeMobile);
      form2.setFieldValue("pSize", site.pSize);
      form2.setFieldValue("pSizeMobile", site.pSizeMobile);
      tags.forEach((t) => {
        if (t.fieldInDb && site?.[t.fieldInDb]) {
          form.setFieldValue(t.fieldInDb, site?.[t.fieldInDb]);
        }
      });
    }
  }, [site, tags]);

  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    console.log("Success:", values);
    if (values && id) {
      dispatch(updateSite(values, id));
    }
  };

  const onSitesUpdate = ({ siteId }: IOnSitesUpdate) => {
    console.log("siteId", siteId);
    if (siteId === id) {
      dispatch(getOneSite(siteId));
      dispatch(getSpecificPageValues(siteId));
      dispatch(getSiteImages(siteId));
    }
  };

  const onInProgressId = ({ inProgressId }: IOnInProgressId) => {
    console.log("inProgressId", inProgressId);
    dispatch(setInProgressSiteId(inProgressId));
  };

  useEffect(() => {
    socket?.on("sitesUpdate", onSitesUpdate);
    socket?.on("inProgressId", onInProgressId);
  }, [socket, id]);

  const { TextArea } = Input;

  const cronOnChange = (checked: boolean) => {
    if (id) {
      dispatch(updateSite({ statusAutoProgressOnCreate: `${checked}` }, id));
    }
  };

  const errorOnChange = (checked: boolean) => {
    if (id) {
      dispatch(updateSite({ error: `${checked}`, isInProgress: false }, id));
    }
  };

  const siteImage =
    siteImages?.find(
      (si) =>
        si.site === id && si.category === site?.company.checkedCategories?.[0]
    ) || siteImages?.find((si) => si.site === id && si.category === "Index");

  const advanageIconsIndexes = [1, 2, 3, 4] as 1[] | 2[] | 3[] | 4[];
  return (
    <div style={{ overflowX: "scroll" }}>
      <div style={{ padding: 20 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            overflowX: "scroll",
          }}
        >
          <Breadcrumb
            style={{ marginTop: 10, marginBottom: 20 }}
            items={[
              {
                href: "/sites",
                title: (
                  <>
                    <ArrowLeftOutlined />
                    <span>Sites</span>
                  </>
                ),
              },
              {
                title: "Site",
              },
            ]}
          />
          {isGettingSitesInProgress && <LoadingOutlined />}
        </div>

        <Title style={{ marginTop: 0 }} level={4}>
          Status flow
        </Title>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {id && site && <StatusBar id={id} site={site} />}
          <div>
            {site?.htmlBuildLocation && (
              <div style={{ marginLeft: 30, marginTop: 25 }}>
                <Link
                  target="_blank"
                  href={`${mainUrl}${site?.htmlBuildLocation?.replace(
                    "/data/uploads/",
                    ""
                  )}`}
                >
                  <LinkOutlined /> HTML BUILD
                </Link>
              </div>
            )}
          </div>
          {siteImages.length > 0 && (
            <Popconfirm
              title="Confirmation"
              description={`Delete?`}
              onConfirm={() => {
                if (id) {
                  dispatch(deleteAllSiteImages(id));
                }
              }}
            >
              <Button
                style={{
                  color: "white",
                  backgroundColor: "red",
                  marginTop: 20,
                  marginLeft: 30,
                }}
                onClick={() => null}
                type="primary"
                disabled={
                  isUpdatingSiteInProgress ||
                  !!inProgressId ||
                  site?.isInProgress
                }
                loading={isImageDeletingInProgress}
              >
                Delete all images
              </Button>
            </Popconfirm>
          )}

          <Popconfirm
            title="Confirmation"
            description={`Build/Rebuild?`}
            onConfirm={() => {
              if (id) {
                dispatch(
                  updateSite(
                    {
                      status: "initial",
                      statusAutoProgressOnCreate: true,
                      isInProgress: false,
                      error: false,
                      htmlBuildLocation: "",
                      isDeployed: false,
                    },
                    id
                  )
                );
              }
            }}
          >
            <Button
              style={{ marginTop: 20, marginLeft: 30 }}
              onClick={() => null}
              type="primary"
              icon={<ReloadOutlined />}
              disabled={
                isUpdatingSiteInProgress || !!inProgressId || site?.isInProgress
              }
              loading={!!inProgressId}
            >
              Build/Rebuild
            </Button>
          </Popconfirm>
          {specificPageValues.length > 0 && (
            <Popconfirm
              title="Confirmation"
              description={`Rebuild with new content?`}
              onConfirm={() => {
                if (id) {
                  dispatch(rebuildWithNewContent(id));
                }
              }}
            >
              <Button
                style={{ marginTop: 20, marginLeft: 30 }}
                onClick={() => null}
                type="primary"
                icon={<ReloadOutlined />}
                disabled={
                  isRebuildWithNewContentInProgress ||
                  !!inProgressId ||
                  site?.isInProgress
                }
                loading={isRebuildWithNewContentInProgress || !!inProgressId}
              >
                Rebuild with new content
              </Button>
            </Popconfirm>
          )}
          {!site?.isRewrited && (
            <Popconfirm
              disabled={
                site?.isRewriteInProgress ||
                site?.needRewrite ||
                isRewriteContentInProgress
              }
              title="Confirmation"
              description={`Rewrite all website content?`}
              onConfirm={() => {
                if (id) {
                  dispatch(rewriteContent(id));
                }
              }}
            >
              <Button
                style={{ marginTop: 20, marginLeft: 30 }}
                onClick={() => null}
                type="primary"
                disabled={site?.isRewriteInProgress || site?.needRewrite}
                loading={
                  site?.isRewriteInProgress || isRewriteContentInProgress
                }
              >
                Rewrite
              </Button>
            </Popconfirm>
          )}
        </div>
      </div>
      <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
        <div style={{ padding: 20, paddingTop: 0 }}>
          <Title style={{ marginBottom: 20, color: "red" }} level={3}>
            BASE INFO, INDEX PAGE
          </Title>
          {siteImage && (
            <div style={{ position: "relative" }}>
              <img
                width="200"
                alt="company logo"
                src={`${mainUrl}${siteImage?.url.replace(
                  "/data/uploads/",
                  ""
                )}`}
              />
              <Popconfirm
                title="Confirmation"
                description={`Delete?`}
                onConfirm={() => {
                  if (id) {
                    dispatch(deleteOneSiteImage(siteImage._id, id));
                  }
                }}
              >
                <Button
                  style={{ position: "absolute", left: 0 }}
                  onClick={() => null}
                  type="primary"
                  loading={isImageDeletingInProgress}
                >
                  Delete
                </Button>
              </Popconfirm>
            </div>
          )}
          <Form
            style={{ minWidth: 400 }}
            form={form}
            layout="vertical"
            //onFinish={onFinish}
          >
            <Form.Item<FieldType>
              label={
                <Title style={{ marginTop: 0 }} level={4}>
                  Site name
                </Title>
              }
              name="name"
              rules={[{ required: true, message: "Site Name can't be empty" }]}
            >
              <Input
                onChange={(val) =>
                  setValuesToUpdate({
                    ...valuesToUpdate,
                    name: val.target.value,
                  })
                }
                size="middle"
                placeholder="Enter site name"
              />
            </Form.Item>
            <Form.Item<FieldType>
              label={
                <Title style={{ marginTop: 0 }} level={4}>
                  Site domain
                </Title>
              }
              name="domain"
            >
              <Input
                size="middle"
                onChange={(val) =>
                  setValuesToUpdate({
                    ...valuesToUpdate,
                    domain: val.target.value,
                  })
                }
              />
            </Form.Item>
            {[...tags].reverse().map((t) =>
              t.fieldInDb && site?.[t.fieldInDb] ? (
                <Form.Item<FieldType>
                  key={t.fieldInDb}
                  label={
                    <Title style={{ marginTop: 0 }} level={4}>
                      {t.fieldInDb}
                    </Title>
                  }
                  name={t.fieldInDb}
                >
                  <TextArea
                    disabled={
                      t.fieldInDb === "companyLocalPhone" ||
                      t.fieldInDb === "companyLocalPhoneFormatted"
                    }
                    rows={2}
                    size="middle"
                    placeholder={`Enter ${t.fieldInDb}`}
                    onChange={(val) =>
                      setValuesToUpdate({
                        ...valuesToUpdate,
                        [t.fieldInDb]: val.target.value,
                      })
                    }
                  />
                </Form.Item>
              ) : null
            )}

            {id && (
              <Button
                style={{ width: 150 }}
                htmlType="submit"
                loading={isUpdatingSiteInProgress}
                type="primary"
                size="middle"
                disabled={false}
                onClick={() => {
                  if (id) {
                    dispatch(updateSite(valuesToUpdate, id));
                  }

                  setValuesToUpdate({});
                }}
              >
                Save
              </Button>
            )}
          </Form>
        </div>

        {id && (
          <SpecificPageValues
            siteId={id}
            appliances={appliances}
            applianceBrands={applianceBrands}
            siteImages={siteImages}
          />
        )}

        <div style={{ borderLeft: "1px solid", paddingLeft: 20 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: 20,
              paddingBottom: 10,
            }}
          >
            <div>
              <Title style={{ marginTop: 0 }} level={4}>
                Autoprogress
              </Title>

              <Switch
                loading={isUpdatingSiteInProgress}
                checked={site?.statusAutoProgressOnCreate}
                onChange={cronOnChange}
              />
            </div>

            <div style={{ marginLeft: 50 }}>
              <Title style={{ marginTop: 0 }} level={4}>
                Error/Stop
              </Title>

              <Switch
                style={{ background: site?.error ? "red" : undefined }}
                loading={isUpdatingSiteInProgress}
                defaultChecked={site?.error}
                checked={site?.error}
                onChange={errorOnChange}
              />
            </div>
          </div>

          {templateTypes.some((tt) => !!site?.[`${tt.type}Template`]) && (
            <div>
              <Title style={{ marginTop: 20 }} level={4}>
                Templates
              </Title>
              <TemplatesList
                templateTypes={templateTypes}
                site={site}
                siteId={id}
              />
            </div>
          )}

          {site?.companyLogo && (
            <div
              style={{
                paddingBottom: 10,
                flexDirection: "row",
                width: "100%",
                display: "flex",
              }}
            >
              <div>
                <Title style={{ marginTop: 20 }} level={4}>
                  Company logo
                </Title>

                <img
                  width={200}
                  alt="company logo"
                  src={`${mainUrl}${site?.companyLogo?.replace(
                    "/data/uploads/",
                    ""
                  )}`}
                />
              </div>
              <div
                style={{
                  paddingTop: 50,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Popconfirm
                  title="Delete"
                  description="Are you sure to delete?"
                  onConfirm={() =>
                    id ? dispatch(deleteField("companyLogo", id)) : null
                  }
                >
                  <Button
                    style={{ width: 150, color: "red" }}
                    loading={isUpdatingSiteInProgress}
                    type="link"
                    size="middle"
                  >
                    Delete logo
                  </Button>
                </Popconfirm>
              </div>
            </div>
          )}

          {advanageIconsIndexes.map((i) => {
            if (site?.[`advantageIcon${i}`]) {
              return (
                <div
                  key={i}
                  style={{
                    paddingBottom: 10,
                    flexDirection: "row",
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <div>
                    <Title style={{ marginTop: 20 }} level={4}>
                      Advantage icon {i}
                    </Title>

                    <img
                      width={200}
                      alt="company logo"
                      src={`${mainUrl}${site?.[`advantageIcon${i}`]?.replace(
                        "/data/uploads/",
                        ""
                      )}`}
                    />
                  </div>
                  <div
                    style={{
                      paddingTop: 50,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Popconfirm
                      title="Delete"
                      description="Are you sure to delete?"
                      onConfirm={() =>
                        id
                          ? dispatch(deleteField(`advantageIcon${i}`, id))
                          : null
                      }
                    >
                      <Button
                        style={{ width: 150, color: "red" }}
                        loading={isUpdatingSiteInProgress}
                        type="link"
                        size="middle"
                      >
                        Delete icon
                      </Button>
                    </Popconfirm>
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
        <div style={{ borderLeft: "1px solid", paddingLeft: 20 }}>
          <Title style={{ marginTop: 20 }} level={3}>
            Site global styles
          </Title>
          <Form
            style={{ minWidth: 400 }}
            form={form2}
            layout="vertical"
            onFinish={onFinish}
          >
            {site?.headerFont && (
              <Form.Item<FieldType>
                label={
                  <Title style={{ marginTop: 0 }} level={4}>
                    Headers font
                  </Title>
                }
                name="headerFont"
              >
                <Select style={{ width: "100%" }} options={defaultFonts} />
              </Form.Item>
            )}
            {site?.paragraphFont && (
              <Form.Item<FieldType>
                label={
                  <Title style={{ marginTop: 0 }} level={4}>
                    Paragraph font
                  </Title>
                }
                name="paragraphFont"
              >
                <Select style={{ width: "100%" }} options={defaultFonts} />
              </Form.Item>
            )}
            <div
              style={{ width: "100%", flexDirection: "row", display: "flex" }}
            >
              {site?.h1Size && (
                <Form.Item<FieldType>
                  label={
                    <Title style={{ marginTop: 0 }} level={4}>
                      H1 size
                    </Title>
                  }
                  name="h1Size"
                >
                  <Input style={{ width: 50 }} size="middle" />
                </Form.Item>
              )}
              {site?.h1SizeMobile && (
                <Form.Item<FieldType>
                  label={
                    <Title style={{ marginTop: 0, marginLeft: 20 }} level={4}>
                      H1 size on mobile
                    </Title>
                  }
                  name="h1SizeMobile"
                >
                  <Input style={{ width: 50, marginLeft: 20 }} size="middle" />
                </Form.Item>
              )}
            </div>
            <div
              style={{ width: "100%", flexDirection: "row", display: "flex" }}
            >
              {site?.h2Size && (
                <Form.Item<FieldType>
                  label={
                    <Title style={{ marginTop: 0 }} level={4}>
                      H2 size
                    </Title>
                  }
                  name="h2Size"
                >
                  <Input style={{ width: 50 }} size="middle" />
                </Form.Item>
              )}
              {site?.h2SizeMobile && (
                <Form.Item<FieldType>
                  label={
                    <Title style={{ marginTop: 0, marginLeft: 20 }} level={4}>
                      H2 size on mobile
                    </Title>
                  }
                  name="h2SizeMobile"
                >
                  <Input style={{ width: 50, marginLeft: 20 }} size="middle" />
                </Form.Item>
              )}
            </div>
            <div
              style={{ width: "100%", flexDirection: "row", display: "flex" }}
            >
              {site?.h3Size && (
                <Form.Item<FieldType>
                  label={
                    <Title style={{ marginTop: 0 }} level={4}>
                      H3 size
                    </Title>
                  }
                  name="h3Size"
                >
                  <Input style={{ width: 50 }} size="middle" />
                </Form.Item>
              )}
              {site?.h3SizeMobile && (
                <Form.Item<FieldType>
                  label={
                    <Title style={{ marginTop: 0, marginLeft: 20 }} level={4}>
                      H3 size on mobile
                    </Title>
                  }
                  name="h3SizeMobile"
                >
                  <Input style={{ width: 50, marginLeft: 20 }} size="middle" />
                </Form.Item>
              )}
            </div>
            <div
              style={{ width: "100%", flexDirection: "row", display: "flex" }}
            >
              {site?.h4Size && (
                <Form.Item<FieldType>
                  label={
                    <Title style={{ marginTop: 0 }} level={4}>
                      H4 size
                    </Title>
                  }
                  name="h3Size"
                >
                  <Input style={{ width: 50 }} size="middle" />
                </Form.Item>
              )}
              {site?.h4SizeMobile && (
                <Form.Item<FieldType>
                  label={
                    <Title style={{ marginTop: 0, marginLeft: 20 }} level={4}>
                      H4 size on mobile
                    </Title>
                  }
                  name="h4SizeMobile"
                >
                  <Input style={{ width: 50, marginLeft: 20 }} size="middle" />
                </Form.Item>
              )}
            </div>
            <div
              style={{ width: "100%", flexDirection: "row", display: "flex" }}
            >
              {site?.pSize && (
                <Form.Item<FieldType>
                  label={
                    <Title style={{ marginTop: 0 }} level={4}>
                      Paragraph size
                    </Title>
                  }
                  name="pSize"
                >
                  <Input style={{ width: 50 }} size="middle" />
                </Form.Item>
              )}
              {site?.pSizeMobile && (
                <Form.Item<FieldType>
                  label={
                    <Title style={{ marginTop: 0, marginLeft: 20 }} level={4}>
                      Paragraph size on mobile
                    </Title>
                  }
                  name="pSizeMobile"
                >
                  <Input style={{ width: 50, marginLeft: 20 }} size="middle" />
                </Form.Item>
              )}
            </div>

            <Button
              style={{ width: 150 }}
              htmlType="submit"
              loading={isUpdatingSiteInProgress}
              type="primary"
              size="middle"
              disabled={false}
            >
              Save
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};
