// components/CallAnalyticsTable.tsx

import React, { useState, useEffect, Dispatch } from "react";
import axios from "axios";
import { CallsThunkType, getCalls } from "../store/actions/callsActions";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../store";
import { convertToAmericanDate } from "../helpers/helpers";

type DurationFilter = "gt1" | "all";

interface Filters {
  startDate: string; // в формате "YYYY-MM-DD" от input type="date"
  endDate: string; // в формате "YYYY-MM-DD" от input type="date"
  domain: string;
  destination: string;
  trunkGroup: string;
  durationFilter: DurationFilter;
  page: number;
}

export const Calls = () => {
  const dispatch = useDispatch() as Dispatch<CallsThunkType>;
  //const [calls, setCalls] = useState<Call[]>([]);
  const [filters, setFilters] = useState<Filters>({
    startDate: "",
    endDate: "",
    domain: "",
    destination: "",
    trunkGroup: "",
    durationFilter: "gt1", // по умолчанию: звонки > 1 минуты
    page: 1,
  });

  // При монтировании компонента задаём диапазон дат по умолчанию (последние 30 дней)
  useEffect(() => {
    const now = new Date();
    const priorDate = new Date();
    priorDate.setDate(now.getDate() - 30);

    const pad = (num: number) => (num < 10 ? "0" + num : num);
    const formatDate = (date: Date): string =>
      `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
        date.getDate()
      )}`;

    setFilters((prev) => ({
      ...prev,
      startDate: formatDate(priorDate),
      endDate: formatDate(now),
    }));
  }, []);

  const fetchCalls = async () => {
    const payload = {
      ...filters,
      startDate: filters.startDate ? filters.startDate + " 00:00:00" : "",
      endDate: filters.endDate ? filters.endDate + " 23:59:59" : "",
    };
    dispatch(getCalls(payload));
  };

  // Запрашиваем данные при изменении страницы
  useEffect(() => {
    if (filters.startDate && filters.endDate) {
      fetchCalls();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
      page: 1, // сбрасываем страницу при изменении фильтров
    }));
  };

  const handleSearch = () => {
    fetchCalls();
  };

  const handlePrevPage = () => {
    if (filters.page > 1) {
      setFilters((prev) => ({ ...prev, page: prev.page - 1 }));
    }
  };

  const handleNextPage = () => {
    setFilters((prev) => ({ ...prev, page: prev.page + 1 }));
  };

  const calls = useSelector((state: AppStateType) => state.calls.calls);
  const isCallsLoadingInProgress = useSelector(
    (state: AppStateType) => state.calls.isCallsLoadingInProgress
  );

  useEffect(() => {
    fetchCalls();
  }, []);

  return (
    <div style={{ padding: 10 }}>
      <h2>Аналитика звонков</h2>
      <div className="filters" style={{ marginBottom: "20px" }}>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <tbody>
            <tr>
              <td
                style={{
                  textAlign: "left",
                  padding: "8px",
                  verticalAlign: "middle",
                  width: "180px",
                }}
              >
                Дата звонка (начало):
              </td>
              <td style={{ padding: "8px" }}>
                <input
                  type="date"
                  name="startDate"
                  value={filters.startDate}
                  onChange={handleInputChange}
                />
              </td>
            </tr>
            <tr>
              <td
                style={{
                  textAlign: "left",
                  padding: "8px",
                  verticalAlign: "middle",
                }}
              >
                Дата звонка (конец):
              </td>
              <td style={{ padding: "8px" }}>
                <input
                  type="date"
                  name="endDate"
                  value={filters.endDate}
                  onChange={handleInputChange}
                />
              </td>
            </tr>
            <tr>
              <td
                style={{
                  textAlign: "left",
                  padding: "8px",
                  verticalAlign: "middle",
                }}
              >
                Домен сайта:
              </td>
              <td style={{ padding: "8px" }}>
                <input
                  type="text"
                  name="domain"
                  value={filters.domain}
                  onChange={handleInputChange}
                  placeholder="Введите домен"
                />
              </td>
            </tr>
            <tr>
              <td
                style={{
                  textAlign: "left",
                  padding: "8px",
                  verticalAlign: "middle",
                }}
              >
                Destination number:
              </td>
              <td style={{ padding: "8px" }}>
                <input
                  type="text"
                  name="destination"
                  value={filters.destination}
                  onChange={handleInputChange}
                  placeholder="Введите номер"
                />
              </td>
            </tr>
            <tr>
              <td
                style={{
                  textAlign: "left",
                  padding: "8px",
                  verticalAlign: "middle",
                }}
              >
                Trunk Group:
              </td>
              <td style={{ padding: "8px" }}>
                <input
                  type="text"
                  name="trunkGroup"
                  value={filters.trunkGroup}
                  onChange={handleInputChange}
                  placeholder="Введите Trunk Group"
                />
              </td>
            </tr>
            <tr>
              <td
                style={{
                  textAlign: "left",
                  padding: "8px",
                  verticalAlign: "middle",
                }}
              >
                Длительность звонка:
              </td>
              <td style={{ padding: "8px" }}>
                <select
                  name="durationFilter"
                  value={filters.durationFilter}
                  onChange={handleInputChange}
                >
                  <option value="gt1">Длительность &gt; 1 минута</option>
                  <option value="all">Все звонки</option>
                </select>
              </td>
            </tr>
            <tr>
              <td></td>
              <td style={{ padding: "8px" }}>
                <button
                  disabled={isCallsLoadingInProgress}
                  onClick={handleSearch}
                >
                  {isCallsLoadingInProgress ? "Loading..." : "Поиск"}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <table
        border={1}
        cellPadding={5}
        style={{ width: "100%", borderCollapse: "collapse" }}
      >
        <thead>
          <tr>
            <th>Дата звонка</th>
            <th>Продолжительность</th>
            <th>Кто звонил</th>
            <th>Кому звонили</th>
            <th>Trunk Group</th>
            <th>Domain</th>
          </tr>
        </thead>
        <tbody>
          {calls.length > 0 ? (
            calls.map((call, index) => (
              <tr key={index}>
                <td>{convertToAmericanDate(call.callStart)}</td>
                <td>{call.durationSecs} сек</td>
                <td>{call.callSource}</td>
                <td>{call.callDestination}</td>
                <td>{call.trunkGroup}</td>
                <td>{call.siteDomain || "—"}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={6}>
                {isCallsLoadingInProgress
                  ? "Loading..."
                  : "Нет данных для отображения"}
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="pagination" style={{ marginTop: "20px" }}>
        <button onClick={handlePrevPage} disabled={filters.page === 1}>
          Предыдущая
        </button>
        <span style={{ margin: "0 10px" }}>Страница {filters.page}</span>
        <button onClick={handleNextPage}>Следующая</button>
      </div>
    </div>
  );
};
