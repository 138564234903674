import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCitiesByPartName,
  LocationThunkType,
} from "../../store/actions/locationActions";
import { AppStateType } from "../../store";
import { FormData, inputStyle } from "../../routes/Clicks";

interface IProps {
  setFormData: (data: FormData) => void;
  formData: FormData;
}

export const CitySelect = ({ setFormData, formData }: IProps) => {
  const dispatch = useDispatch() as Dispatch<LocationThunkType>;
  const [inputVal, setInputVal] = useState("");
  const [prevCityId, setPrevCityId] = useState("");
  const [data, setData] = useState<{ value: string; text: string }[]>([]);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputVal(e.target.value);
  };
  const citiesSuggestions = useSelector(
    (state: AppStateType) => state.location.citiesSuggestions
  );

  useEffect(() => {
    if (inputVal.length >= 3) {
      dispatch(getCitiesByPartName(inputVal));
    }
  }, [inputVal]);

  useEffect(() => {
    const data = citiesSuggestions.map((item: any) => ({
      value: item[1],
      text: item[0],
    }));
    setData(data);
  }, [citiesSuggestions]);

  useEffect(() => {
    if (prevCityId && !formData.cityId) {
      setInputVal("");
    }

    setPrevCityId(formData.cityId || "");
  }, [formData]);

  return (
    <div style={{ position: "relative" }}>
      <h3>City</h3>
      <input
        style={inputStyle}
        type="text"
        name="cityId"
        placeholder="At least 3 characters"
        value={inputVal}
        //onChange={handleChange}
        onChange={handleInputChange}
        className="border p-2"
      />
      <div
        style={{
          position: "absolute",
          top: 95,
          left: 0,
          background: "white",
          zIndex: 300,
        }}
      >
        {data.map((item) => (
          <div
            onClick={() => {
              setFormData({
                startDate: formData.startDate,
                endDate: formData.endDate,
                cityId: item.value,
              });
              setInputVal(item.text);
            }}
            style={{
              background: "white",
              marginTop: 10,
              cursor: "pointer",
              zIndex: 100,
              fontSize: 14,
            }}
          >
            {item.text}
          </div>
        ))}
      </div>
    </div>
  );
};
