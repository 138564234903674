import { useEffect, useState } from "react";
import axios from "axios";
import { Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
import { CitySelect } from "../components/stats/citySelect";
import { mainUrl } from "../helpers/constants";
import { DatePicker, Modal } from "antd";
import dayjs from "dayjs";

export interface FormData {
  companyId?: string;
  startDate: string;
  endDate: string;
  url?: string;
  cityId?: string;
  zip?: string;
  countyId?: string;
  stateId?: string;
  name?: string;
}

interface QueryData {
  query: string;
  url: string;
  clicks: number;
  impressions: number;
  ctr: number;
  position: number;
  company: string;
  siteId: string;
  date?: string;
}

interface StatData {
  _id: string;
  date: string;
  totalClicks: number;
  totalImpressions: number;
  averageCtr: number;
  averagePosition: number;
  topQueries: QueryData[];
}

export const inputStyle = {
  height: 38,
  borderColor: "#d9d9d9",
  borderWidth: 1,
  borderStyle: "solid",
  borderRadius: 6,
  paddingLeft: 10,
  color: "rgba(0, 0, 0, 0.88)",
};

export function Clicks() {
  const token = localStorage.getItem("token");
  const [formData, setFormData] = useState<FormData>({
    startDate: dayjs().subtract(30, "day").format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
  });
  // const [formData, setFormData] = useState<FormData>({
  //   startDate: "",
  //   endDate: "",
  // });
  const [stats, setStats] = useState<StatData[] | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [allQueries, setAllQueries] = useState<QueryData[]>([]);
  const [isInProgress, setIsInProgress] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedData, setSelectedData] = useState<{
    _id: string;
    date: string;
  } | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      [
        "url",
        "cityId",
        "zip",
        "countyId",
        "stateId",
        "companyId",
        "name",
      ].includes(e.target.name)
    ) {
      setFormData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const fetchStats = async () => {
    setIsInProgress(true);
    setFormData((prev) => {
      const { startDate, endDate, ...filters } = prev;
      const filterKeys = Object.keys(filters).filter(
        (key) => filters[key as keyof typeof filters]
      );
      if (filterKeys.length > 1) {
        setError("Only one secondary filter can be applied");
        return prev;
      }
      return prev;
    });
    try {
      setError(null);
      const { startDate, endDate, ...filters } = formData;
      if (!startDate || !endDate) {
        setError("startDate and endDate are required");
        return;
      }
      const filterKeys = Object.keys(filters).filter(
        (key) => filters[key as keyof typeof filters]
      );
      if (filterKeys.length > 1) {
        setError("Only one secondary filter can be applied");
        return;
      }
      if (formData.companyId) {
        filterKeys.push("companyId");
      }
      const response = await axios.post<{ status: string; stats: StatData[] }>(
        `${mainUrl}api/v1/domain/agregated-stats`,
        { ...formData },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setStats(
        response.data.stats
          .sort(
            (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
          )
          .map((s) => ({
            ...s,
            date: new Date(s.date).toLocaleDateString(),
          }))
      );
    } catch (err: any) {
      setError(err.response?.data?.message || "Error fetching stats");
    }
    setIsInProgress(false);
  };

  useEffect(() => {
    let aStats: QueryData[] = [];
    if (stats) {
      stats.forEach((stat) => {
        let edited: QueryData[] = [];
        stat.topQueries.forEach((q) => {
          edited.push({ ...q, date: stat.date });
        });
        aStats = [...aStats, ...edited];
      });
    }
    setAllQueries(aStats);
  }, [stats]);

  useEffect(() => {
    fetchStats();
  }, []);

  return (
    <div style={{ padding: 10 }} className="p-4">
      <h1 className="text-xl font-bold mb-4">Aggregated Stats</h1>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "16px",
        }}
      >
        <div>
          <h3>Start date</h3>
          <DatePicker
            format="MM/DD/YYYY"
            value={
              formData.startDate
                ? dayjs(formData.startDate, "YYYY-MM-DD")
                : null
            }
            onChange={(date) =>
              setFormData((prev) => ({
                ...prev,
                startDate: date ? date.format("YYYY-MM-DD") : "",
              }))
            }
            style={{ height: 40 }}
          />
        </div>
        <div>
          <h3>End date</h3>
          <DatePicker
            format="MM/DD/YYYY"
            value={
              formData.endDate ? dayjs(formData.endDate, "YYYY-MM-DD") : null
            }
            onChange={(date) =>
              setFormData((prev) => ({
                ...prev,
                endDate: date ? date.format("YYYY-MM-DD") : "",
              }))
            }
            style={{ height: 40 }}
          />
        </div>
        <div>
          <h3>Domain</h3>
          <input
            style={inputStyle}
            type="text"
            name="url"
            placeholder="domain.com"
            value={formData.url || ""}
            onChange={handleChange}
            className="border p-2"
          />
        </div>
        <CitySelect setFormData={setFormData} formData={formData} />
        <div>
          <h3>Zip</h3>
          <input
            style={inputStyle}
            type="text"
            name="zip"
            placeholder="ZIP"
            value={formData.zip || ""}
            onChange={handleChange}
            className="border p-2"
          />
        </div>
        <div>
          <h3>County ID</h3>
          <input
            style={inputStyle}
            type="text"
            name="countyId"
            placeholder="County ID"
            value={formData.countyId || ""}
            onChange={handleChange}
            className="border p-2"
          />
        </div>
        <div>
          <h3>State ID</h3>
          <input
            style={inputStyle}
            type="text"
            name="stateId"
            placeholder="State ID"
            value={formData.stateId || ""}
            onChange={handleChange}
            className="border p-2"
          />
        </div>
        <div>
          <h3>Company ID</h3>
          <input
            style={inputStyle}
            type="text"
            name="companyId"
            placeholder="Company ID"
            value={formData.companyId || ""}
            onChange={handleChange}
            className="border p-2"
          />
        </div>
        <div>
          <h3>Site name</h3>
          <input
            style={inputStyle}
            type="text"
            name="name"
            placeholder="e.g. 703-Coronado-92118"
            value={formData.name || ""}
            onChange={handleChange}
            className="border p-2"
          />
        </div>
        <button
          disabled={isInProgress}
          style={{
            height: 40,
            marginTop: 60,
            borderRadius: 6,
            cursor: "pointer",
          }}
          onClick={fetchStats}
          className="bg-blue-500 text-white p-2 rounded"
        >
          {isInProgress ? "Loading" : "Fetch Stats"}
        </button>
      </div>

      {error && <p style={{ color: "red" }}>{error}</p>}
      {stats && (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "16px",
            marginTop: "24px",
          }}
        >
          <div style={{ width: "100%", height: "256px" }}>
            <h2 className="text-lg font-bold">Total Clicks</h2>
            <LineChart
              width={700}
              height={200}
              data={stats}
              onClick={(e) => {
                if (e && e.activePayload && e.activePayload.length) {
                  setSelectedData(e.activePayload[0].payload);
                  setModalVisible(true);
                }
              }}
            >
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Line type="monotone" dataKey="totalClicks" stroke="#8884d8" />
            </LineChart>
          </div>
          <div className="w-full h-64">
            <h2 className="text-lg font-bold">Total Impressions</h2>
            <LineChart
              width={700}
              height={200}
              data={stats}
              onClick={(e) => {
                if (e && e.activePayload && e.activePayload.length) {
                  setSelectedData(e.activePayload[0].payload);
                  setModalVisible(true);
                }
              }}
            >
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="totalImpressions"
                stroke="#82ca9d"
              />
            </LineChart>
          </div>
          <div className="w-full h-64">
            <h2 className="text-lg font-bold">Average Ctr</h2>
            <LineChart
              width={700}
              height={200}
              data={stats}
              onClick={(e) => {
                if (e && e.activePayload && e.activePayload.length) {
                  setSelectedData(e.activePayload[0].payload);
                  setModalVisible(true);
                }
              }}
            >
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Line type="monotone" dataKey="averageCtr" stroke="#ff7300" />
            </LineChart>
          </div>
          <div className="w-full h-64">
            <h2 className="text-lg font-bold">Average Position</h2>
            <LineChart
              width={700}
              height={200}
              data={stats}
              onClick={(e) => {
                if (e && e.activePayload && e.activePayload.length) {
                  setSelectedData(e.activePayload[0].payload);
                  setModalVisible(true);
                }
              }}
            >
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="averagePosition"
                stroke="#d84315"
              />
            </LineChart>
          </div>
        </div>
      )}
      {stats && (
        <table
          style={{
            width: "100%",
            marginTop: "16px",
            borderCollapse: "collapse",
          }}
        >
          <thead>
            <tr style={{ borderBottom: "2px solid black" }}>
              <th style={{ textAlign: "left", padding: 10 }}>Date</th>
              <th style={{ textAlign: "left", padding: 10 }}>URL</th>
              <th style={{ textAlign: "left", padding: 10 }}>Query</th>
              <th style={{ textAlign: "left", padding: 10 }}>Clicks</th>
              <th style={{ textAlign: "left", padding: 10 }}>Impressions</th>
              <th style={{ textAlign: "left", padding: 10 }}>CTR</th>
              <th style={{ textAlign: "left", padding: 10 }}>Position</th>
            </tr>
          </thead>
          <tbody>
            {allQueries
              .sort((a, b) => b.clicks - a.clicks || a.position - b.position)
              .map((query, index) => (
                <tr key={index} style={{ borderBottom: "1px solid gray" }}>
                  <td style={{ padding: 10 }}>
                    {/* @ts-ignore */}
                    {query?.date?.split("/")[1]}/{query?.date?.split("/")[0]}/
                    {query?.date?.split("/")[2]}
                  </td>
                  <td style={{ padding: 10 }}>{query.url}</td>
                  <td
                    style={{
                      padding: 10,
                      fontWeight: query.clicks > 0 ? "bold" : undefined,
                    }}
                  >
                    {query.query}
                  </td>
                  <td style={{ padding: 10 }}>{query.clicks}</td>
                  <td style={{ padding: 10 }}>{query.impressions}</td>
                  <td style={{ padding: 10 }}>
                    {(query.ctr * 100).toFixed(2)}%
                  </td>
                  <td style={{ padding: 10 }}>{query.position}</td>
                </tr>
              ))}
          </tbody>
        </table>
      )}

      <Modal
        title="Detailed Information"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        width={900}
      >
        {allQueries
          .filter((a) => a.date === selectedData?.date)
          .sort((a, b) => b.clicks - a.clicks || a.position - b.position)
          .map((query, index) => (
            <tr key={index} style={{ borderBottom: "1px solid gray" }}>
              <td style={{ padding: 10 }}>
                {/* @ts-ignore */}
                {query?.date?.split("/")[1]}/{query?.date?.split("/")[0]}/
                {query?.date?.split("/")[2]}
              </td>
              <td style={{ padding: 10 }}>{query.url}</td>
              <td
                style={{
                  padding: 10,
                  fontWeight: query.clicks > 0 ? "bold" : undefined,
                }}
              >
                {query.query}
              </td>
              <td style={{ padding: 10 }}>{query.clicks}</td>
              <td style={{ padding: 10 }}>{query.impressions}</td>
              <td style={{ padding: 10 }}>{(query.ctr * 100).toFixed(2)}%</td>
              <td style={{ padding: 10 }}>{query.position}</td>
            </tr>
          ))}
      </Modal>

      {stats && (
        <div className="mt-4">
          <h2 className="text-lg font-bold">Results:</h2>
          <pre className="bg-gray-100 p-4 rounded">
            {JSON.stringify(stats, null, 2)}
          </pre>
        </div>
      )}
    </div>
  );
}
